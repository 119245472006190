<template>

    <div class="section" dir="rtl">
        <div class="container">

            <div v-if="(article.category == 1)" class="breadcrumb mb-10 text-bold" id="biography" >{{  $t('council.title') }} >  {{  $t('welcome.lastActivitiesPresident') }}</div>
            <div v-if="(article.category == 2)" class="breadcrumb mb-10 text-bold" id="biography" >{{  $t('council.title') }} >  {{  $t('welcome.lastActivities') }}</div>
            <div v-if="(article.category == 3)" class="breadcrumb mb-10 text-bold" id="biography" >{{  $t('council.title') }} >  {{  $t('welcome.advertisements.title') }}</div>

        </div>
    </div>
    <div class="container article-bloc mb-5" dir="rtl" >
        <div class="activities mt-4">
            <h4><span>|</span>{{ article.title }}</h4>
            <div class="date">
                <span>{{ date_article(article.date_article) }}</span>
            </div>

            <!--One-->
            <div v-if="photos_rows === 1" class="row justify-content-center" >
                <div v-for="photo in photos" class="col-md-6">
                    <a target="_blank" :href="url_back +'images/activities/' + photo.url_big">
                        <img :src="url_back +'images/activities/' + photo.url_big" width="500" class="img-thumbnail float-end float-md-start"  style="max-height: 400px" alt="...">
                    </a>
                </div>
            </div>
            <!--Plus One photo-->
            <div class="container row mx-2">
                <div v-if="photos_rows >= 2" class="row mt-4">
                    <Carousel paginationColor="gray" :autoplay="5000" :wrap-around="true"  :settings="settings" :breakpoints="breakpoints"  style="margin-top: 20px">

                        <Slide v-for="photo in photos" :key="photo.id">
                            <a target="_blank" :href="url_back + 'images/activities/' + photo.url_big">
                                <div class="carousel__item">
                                        <img :src="url_back + 'images/activities/' + photo.url_big " class="img-thumbnail" alt="..." style="height: 200px">
                                </div>
                            </a>
                        </Slide>

                        <template #addons>
                            <Navigation />
                            <Pagination />
                        </template>
                    </Carousel>
                </div>
            </div>
            <!--Description of article-->
            <div class="description text-block" v-html="article.description"></div>

            <div v-if="article.file !== '' && article.file !== null" >
                <div class="mb-3">
                    تحمبل الملف :<a :href="url_back_file + article.file" target="_blank" style="margin-right: 8px"><span><img :src="'/images/icons/download.png'" alt=""></span></a>
                </div>
            </div>

            <!--Share on social media-->
            <div class="social_media text-start">
                <h6>مشاركة المقال</h6>
                <div class="social_icons">
                    <a target="_blank" class="share-ico share-fb" :href="'https://www.facebook.com/sharer/sharer.php?u='+'https://www.hatplc.dz/detail_article/'+article.id ">
                        <font-awesome-icon icon="fa-brands fa-facebook-f" />
                    </a>
                    <a target="_blank" class="share-ico share-tw mx-3" :href="'https://twitter.com/intent/tweet?text='+'https://www.hatplc.dz/detail_article/'+article.id">
                        <img src="../../assets/square-x-twitter.svg" height="22" alt="">
                    </a>
                    <a target="_blank" class="share-ico share-link" :href="'https://www.linkedin.com/shareArticle?url='+'https://www.hatplc.dz/detail_article/'+article.id">
                        <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

    export default {
        name: "Detail_article",
        
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation
        },

        setup() {
            return {
                // carousel settings
                settings: {
                    itemsToShow: 1,
                    snapAlign: "center"
                },
                // breakpoints are mobile first
                // any settings not specified will fallback to the carousel settings
                breakpoints: {
                    // 700px and up
                    600: {
                        itemsToShow: 1,
                        snapAlign: "start"
                    },
                    // 700px and up
                    700: {
                        itemsToShow: 2,
                        snapAlign: "center"
                    },

                    // 700px and up
                    900: {
                        itemsToShow: 3,
                        snapAlign: "center"
                    },
                    // 1024 and up
                    1024: {
                        itemsToShow: 4,
                        snapAlign: "center"
                    }
                }
            };
        },
        data(){
            return {
                article:{},
                photos:this.photos,
                photos_rows: this.photos_rows,
                url_back: process.env.VUE_APP_ROOT_API +'uploads/',
                url_back_file: process.env.VUE_APP_ROOT_API + 'uploads/file/',
                url_img:'',
            }
        },
        created(){
        },
        computed: {
            // backgroundImageInlineStyle () {
            //     return `background-image: url("${this.url_img}");background-size: contain;background-position: center;`;
            // },
        },
        methods:{

            async getArticleById(){
                $('.wait_circle').removeClass('d-none');
                let article_id = this.$route.params.id;
                if(!article_id){
                    this.$router.replace({ path: '/PageNotFound' });
                }
                if(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(article_id)){
                    let url = process.env.VUE_APP_ROOT_API + `api/detail_article/${this.$route.params.id}`;
                    await axios.get(url).then(response => {
                        $('.wait_circle').addClass('d-none');

                        if(Object.keys(response.data.article).length){
                            this.article = response.data.article;
                            console.log(response.data.article);
                            var url = response.data.video.lien_yt;
                            if(url != '' && url != null){
                                //var pathArray = url.split( '/' );
                                //this.url_img = 'https://i.ytimg.com/vi/' + pathArray[3] +'/hqdefault.jpg';
                                this.url_img = response.data.video.img_url;
                            }
                        }else {
                            this.$router.replace({ path: '/PageNotFound' });
                        }
                    });

                    let url_photos = process.env.VUE_APP_ROOT_API + `api/photos/${this.$route.params.id}`;
                    await axios.get(url_photos).then(response => {
                        let nb_photos = Object.keys(response.data).length;
                        this.photos_rows = nb_photos;
                        this.photos = response.data;
                    });
                }
                else {
                    $('.wait_circle').addClass('d-none');
                    this.$router.replace({ path: '/PageNotFound' });
                }
            },
            date_article(value){
                let day = moment(value).format('DD');
                let month = moment(value).format('MM');
                let year = moment(value).format('YYYY');

                if(month === "01"){
                    month = "جانفي";
                }else if(month === "02"){
                    month = "فيفري";
                }else if(month === "03"){
                    month = "مارس";
                }else if(month === "04"){
                    month = "أفريل";
                }else if(month === "05"){
                    month = "ماي";
                }else if(month === "06"){
                    month = "جوان";
                }else if(month === "07"){
                    month = "جويلية";
                }else if(month === "08"){
                    month = "أوت";
                }else if(month === "09"){
                    month = "سبتمبر";
                }else if(month === "10"){
                    month = "أكتوبر";
                }else if(month === "11"){
                    month = "نوفمبر";
                }else if(month === "12"){
                    month = "ديسمبر";
                }
                return day +' '+  month +' '+ year;
            },
       },
        mounted: function () {
            this.getArticleById();
        }
    }

</script>

<style scoped>

    @media(min-width: 800px) {
        .article-bloc{
            min-height: 700px;
        } 
    }

    .title_video h5{
        font-size: var(--s-font-size);
        font-weight: 700;
        line-height: calc(23/16 * var(--default-size-unit));
        /*max-width: calc(295/16 * var(--default-size-unit));*/
        /*white-space: nowrap;*/
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
    }

    .text-block {
        white-space: pre-line;
        line-height: 30px;
        text-align: justify;
    }

    .activities{
        font-family: DroidArabic!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin: 30px!important;
    }
    @media(max-width: 800px) {
        .description{
            margin: 30px 10px 30px 10px!important;
        }

    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }

    /* Start carousel*/

    .carousel__item {
        min-height: 200px;
        width: 100%;
        /*background-color: var(--vc-clr-primary);*/
        /*color:  var(--vc-clr-white);*/
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel__slide {
        padding: 10px;
    }
    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid red;
    }

    .carousel__slide > .carousel__item {
        transform: scale(1);
        opacity: 0.5;
        transition: 0.5s;
    }
    .carousel__slide--visible > .carousel__item {
        opacity: 1;
        transform: rotateY(0);
    }
    .carousel__slide--next > .carousel__item {
        transform: scale(0.9) translate(-10px);
    }
    .carousel__slide--prev > .carousel__item {
        transform: scale(0.9) translate(10px);
    }
    .carousel__slide--active > .carousel__item {
        transform: scale(1.1);
    }

    /*End Carousel*/

    /*start social media*/
    .sm-share h6{
        font-family: Droid!important;
        font-size: 15px;
        color: #17a2b8;
    }
    .social_media h6{
        color: #17a2b8;
        margin-top: 50px;
    }
    .share-ico{
        color: #999999;
        font-size: 1.5em;
       
    }
    .share-fb{
        color: #3b5999;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }
    .share-tw{
        color: #55acee;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }
    .share-link{
        color: #0077B5;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }

    /*End social media*/


</style>